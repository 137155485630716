<template>
	<div @keydown.ctrl="abrirPegar">
		<v-card-text>
			<span v-for="(mensaje, i) in filterMensajes" :key="i">
		    <div class="message text-only mb-8">
		      <div :class="`${ mensaje.mio == 0 ? 'noresponse' : 'response' }`">
		      	<!-- Tipo de texto es textooo -->
		        <p 
		          class="text" 
		          v-if="mensaje.type == 1"
		          style="white-space: pre-line"
		          @contextmenu="show(mensaje, $event)"
		        >
		          {{ mensaje.mensaje }}
		          <!-- <v-btn
				        class="forward-btn"
				        icon="mdi-share"
				        size="small"
				        variant="text"
				        @click="forwardMessage(mensaje)"
				      >
				      </v-btn> -->
		        </p>

		        <!-- Tipo de texto es Imagennnnn -->
		        <p 
		          class="text" 
		          v-if="mensaje.type == 2"
		          style="white-space: pre-line"
		          @click="verImagen( mensaje.MediaUrl0 )"
		          type="button"
		          @contextmenu="show(mensaje, $event)"
		        >
		          <img v-if="mensaje.mio == 0 && !mensaje.fake" :src="url + mensaje.MediaUrl0" alt="Red dot" width="300" />
		          <img v-if="mensaje.mio == 1 && !mensaje.fake" :src="url2 + mensaje.MediaUrl0" alt="Red dot" width="300" />

		          <!-- Ruta para imagenes fake -->
		          <img v-if="mensaje.fake" :src="mensaje.imagenFake" alt="Red dot" width="300" />
		        </p>

		        <!-- Para cuando se envían stickers -->
		        <p 
		          class="text" 
		          v-if="mensaje.type == 'sticker'"
		          style="white-space: pre-line"
		          @contextmenu="show(mensaje, $event)"
		        >
		          <img v-if="mensaje.mio == 0" :src="url + mensaje.MediaUrl0" alt="Red dot" width="150"/>
		          <img v-if="mensaje.mio == 1" :src="url2 + mensaje.MediaUrl0" alt="Red dot" width="150"/>
		        </p>

		        <!-- Tipo PDF -->
		        <p 
		          class="text" 
		          v-if="mensaje.type == 4"
		          style="white-space: pre-line"
		          @contextmenu="show(mensaje, $event)"
		        >
		          <v-chip
		            color="#f1f3f4"
		            label
		            text-color="black"
		            @click="verPDF( mensaje.MediaUrl0 )"
		          >
		            <v-icon left>
		              mdi-file-pdf-box
		            </v-icon>

		          </v-chip>
		          	<embed v-if="mensaje.mio == 0 && !mensaje.fake" :src="url + mensaje.MediaUrl0 " type="application/pdf"  width="100%" height="350px" />
		          	<embed v-if="mensaje.mio == 1 && !mensaje.fake" :src="url2 + mensaje.MediaUrl0 " type="application/pdf" width="100%" height="350px" />

		          	<embed v-if="mensaje.fake" :src="`${mensaje.imagenFake}#toolbar=0`" type="application/pdf" width="100%" height="350px" />
		        </p>

						<!-- Tipo Audio -->
		        <p 
		          class="text pa-0 pt-2 px-2 audio" 
		          v-if="mensaje.type == 3"
		          style="white-space: pre-line; width: 300px;"
		          @contextmenu="show(mensaje, $event)"
		        >
		          <audio controls style="width: 100%;">
		            <source v-if="mensaje.mio == 0" :src="url + mensaje.MediaUrl0" type="audio/ogg" >
		            <source v-if="mensaje.mio == 1" :src="url2 + mensaje.MediaUrl0" :type="`audio/${mensaje.MediaUrl0.substring(mensaje.MediaUrl0.length - 3, mensaje.MediaUrl0.length) == 'mp4' ? 'mp4' : 'mp3'}`" >
		          </audio>
		        </p>

		        <!-- Tipo video -->
		        <p 
		          class="text" 
		          v-if="mensaje.type == 5"
		          style="white-space: pre-line"
		          @contextmenu="show(mensaje, $event)"
		        >
		          <video controls width="300">
		            <source v-if="mensaje.mio == 0" :src="url + mensaje.MediaUrl0">
		            <source v-if="mensaje.mio == 1" :src="url2 + mensaje.MediaUrl0">
		          </video>
		        </p>

		        <!-- Mensaje -->
		        <p 
		          class="text mt-2" 
		          v-if="mensaje.type == 2 && mensaje.mensaje"
		          style="white-space: pre-line"
		        >
		          {{ mensaje.mensaje }}
		        </p>

		        <!-- Mensaje -->
		        <p 
		          class="text mt-2" 
		          v-if="mensaje.type == 4 && mensaje.mensaje"
		          style="white-space: pre-line"
		        >
		          {{ mensaje.mensaje }}
		        </p>

		        <!-- Nombre del usuario -->
		        <p 
		          v-if="mensaje.mio == 1"
		          :class="`${ mensaje.mio == 0 ? 'time' : 'response-time time'} black--text`"
		        >{{ mensaje.nombre_completo }}  <br/></p>


		        <p 
		          :class="`${ mensaje.mio == 0 ? 'time' : 'response-time time'} black--text`"
		        >{{ mensaje.fecha_creacion }}</p>

		      </div>
		    </div>
		  </span>
		</v-card-text>

		<v-card-actions id="sectionMessage" v-if="chat.tiemporestante > 0">
		  		
		  	<!-- Botones para el audio -->
				<v-btn color="red"     small @click="deleteAudio()" class="mr-2" v-if="audio" dark><v-icon>mdi-delete</v-icon></v-btn>

				<v-btn color="orange"  small @click="pauseAudio()"  class="mr-2" v-if="audio" dark><v-icon>mdi-pause</v-icon></v-btn>

				<v-btn color="blue"    small @click="playAudio()"   class="mr-2" v-if="audio" dark><v-icon>mdi-play</v-icon></v-btn>

				<v-btn color="success" small @click="sendAudio()"   class="mr-2" v-if="audio" dark><v-icon>mdi-send</v-icon></v-btn>

				<!-- Progress del audi -->
				<v-progress-linear
					v-if="audio && grabando"
					class="mt-2"
		      indeterminate
		      color="green"
		    ></v-progress-linear>

		    <!-- Cuadro de texto para enviar archivos -->
			  <v-textarea
			  	v-if="!audio"
			    label="Escribe un mensaje aquí"
			    solo
			    rounded
			    hide-details
			    single-line
			    dense
			    auto-grow
			    rows="1"
			    v-model="message"
			    :append-outer-icon="message ? 'mdi-send' : 'mdi-microphone'"
			    prepend-icon="mdi-paperclip"
			    @click:append-outer="message ? sendMessage() : grabarAudio()"
			    @click:prepend="vistaPrevia = null, file = null, dialogArchivos = true"
			    @keyup.enter="sendMessage"
			  ></v-textarea>
		</v-card-actions>

		<v-card-actions id="sectionMessage" v-else>
  		<v-alert
			  color="red"
			  type="warning"
			>
				Hola, este contacto ya no puede recibir mensajes, debido a que la ventana de <b>24 horas se ha cerrado</b>, en caso de requerir enviar un mensaje, favor de comunicarse con el departamento de <b>COMERCIAL</b>
			</v-alert>
		</v-card-actions>
	  
	  <!-- Sección para enviar un mensaje -->

	  <!-- Chip de asignación -->
	  <!-- <v-btn color="orange" tile absolute right class="mt-10" top dark v-if="asignacion && asignacion.vendedora">{{ asignacion.vendedora }}</v-btn> -->
	  
	  <!-- DIALGO PARA AGREGAR UN ARCHIVO -->
    <v-dialog
	    v-model="dialogArchivos"
	    :max-width="400"
		  @keydown.ctrl="pegar"
	  >
	  	<v-card class="mx-auto shadowCard" >
		    <v-card-title class="text-h6 font-weight-regular justify-space-between">
		      <span class="text-subtitle-1"> Agregar archivo </span>
		    </v-card-title>

		    <v-card-text align="center">
		      <div v-if="!file">
            <div :class="['dropZone', dragging ? 'dropZone-over' : '']" @dragenter="dragging = true" @dragleave="dragging = false">
              <div class="dropZone-info" @drag="onChange">
                <span class="fa fa-cloud-upload dropZone-title"></span>
                <span class="dropZone-title blue--text"><b>Arrastre el archivo para cargar</b></span>
                <div class="dropZone-upload-limit-info">
                  <div>Tamaño máximo: 40 MB</div>
                </div>
              </div>
              <input type="file" @change="onChange">
            </div>
          </div>
         
          <div v-else>
          	<v-card class="elevation-0 transparent">
			        <img v-if="vistaPrevia && tipoArchivo == 2" :src="vistaPrevia" alt="Red dot" width="100%"/>
			        <iframe 
			        	v-if="vistaPrevia && tipoArchivo == 4"
					      :src="`${vistaPrevia}#toolbar=0`" 
					      width="100%" 
					      height="300px" 
					      style="border: none;"
					    ></iframe>

			      </v-card>

			      <v-card
			      	class="mt-2"
			      >
			        <v-textarea
						    label="Escribe un mensaje aquí"
						    filled
						    rounded
						    hide-details
						    dense
						    auto-grow
						    rows="1"
						    v-model="message"
						  ></v-textarea>
			      </v-card>

          </div>
		    </v-card-text>

		    <v-card-actions>
		      <v-btn 
		      	color="black" 
		      	dark 
		      	small 
		      	tile 
		      	@click="cancelar()"
		      	absolute
		      	top
		      	right
		      	icon
		      >
		      	<v-icon small left>mdi-close</v-icon>
		      </v-btn>
		      
		      <v-spacer></v-spacer>
		      
		      <v-btn color="success" block dark small tile @click="sendMessage()">
		      	Enviar
		      	<v-icon small right> mdi-send </v-icon>
		      </v-btn>
		    
		    </v-card-actions>
		  </v-card>
		</v-dialog>

		<!-- Dialog para ver imágenes -->
		<v-dialog v-model="dialgImagen" max-width="800px" persistent :fullscreen="fullscreen">
      <v-card class="elevation-0">
        <v-card-text>
          <!-- Imagen -->
          <v-col cols="12">
            <v-card height="100%" class="elevation-0" align="center" outlined>
              <v-img :src="url + imagen" contain aspect-ratio="2" v-if="fullscreen">
              </v-img>

              <v-img :src="url + imagen" contain aspect-ratio="2" max-width="450" v-else >
              </v-img>
            </v-card>
          </v-col>
        </v-card-text>
        <v-card-actions class="pt-0" >
          <v-spacer></v-spacer>
          <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
          <v-btn color="error" text large class="mr-2" @click="fullscreen = true" v-if="!fullscreen">Zoom</v-btn>
          <v-btn color="error" text large class="mr-2" @click="fullscreen = false" v-else>Sin, Zoom</v-btn>
          <!-- Guardar la información  -->
          <v-btn
            color="primary" 
            dark 
            class="elevation-6" 
            large 
            @click="dialgImagen = false"
          >Okey</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- VER PDF -->
    <v-dialog v-model="dialogPDF" max-width="650px" persistent>
      <v-card class="elevation-0">
        <!-- Imagen -->
        <embed :src="wha.url_servidor + '/whatsapp-imagenes/' + pdf" width="650" height="700" type="application/pdf" v-if="wha">
        <v-card-actions class="pt-0" >
          <v-spacer></v-spacer>
          <!-- Guardar la información  -->
          <v-btn
            color="error" 
            dark 
            class="elevation-6" 
            block
            @click="dialogPDF = false"
          >Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogMicrofono"
      persistent
      max-width="490"
    >
      <v-card>
        <v-card-title class="text-h5">
          Conectar micrófono
        </v-card-title>
        <v-card-text>
        	Google Chrome:<br/>
					Ve a Configuración > Privacidad y seguridad > Configuración del sitio > Micrófono.
					<br/>Busca tu sitio en la lista y cambia la opción a "Permitir".
					<br/><br/>
					Firefox:
					<br/>Ve a Preferencias > Privacidad y seguridad > Permisos > Micrófono.
					Administra los sitios web que tienen acceso.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#017561"
            dark
            rounded
            @click="dialogMicrofono = false"
          >
            Enterado
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog
      v-model="dialogAudio"
      persistent
      max-width="490"
    >
      <v-card>
        <v-card-title class="text-h5">
        </v-card-title>

        <v-card-text class="mt-6">

      		<v-progress-linear
			      :indeterminate="grabando"
			      color="green"
			      height="25"
			      rounded
			      :value="grabando ? 0 : 100"
			    >
			    	<v-icon color="white" small>mdi-microphone</v-icon>
			    </v-progress-linear>
      		<audio v-if="audioVisible" :src="audioURL" controls></audio>


        	<div class="text-center mt-6" align="center">

        		<v-btn 
        			color="grey"
        			class="mr-2"
        			small
        			fab
        			@click="deleteAudio( )"
        		>
        			<v-icon color="white">mdi-delete</v-icon>
        		</v-btn>

        		<v-btn 
        			class="mr-2"
        			color="red"
        			small
        			fab
          		v-if="grabando == true"
        			@click="pauseAudio( )"
        		>
        			<v-icon color="white">mdi-stop</v-icon>
        		</v-btn >

        		<v-btn 
        			class="mr-2"
        			color="red"
        			small
        			fab
          		v-else
        			@click="playAudio( )"
        		>
        			<v-icon color="white">mdi-play</v-icon>
        		</v-btn>

        		<v-btn 
        			color="green"
        			small
        			fab
        			@click="sendAudio()"
        		>
        			<v-icon color="white">mdi-send</v-icon>
        		</v-btn>
        	</div>

        </v-card-text>
        <v-card-actions>
          <v-btn 
          	icon
          	absolute
          	top
          	right
          	@click="deleteAudio( )"
          >
        		<v-icon>mdi-close</v-icon>
        	</v-btn>
        </v-card-actions>	
      </v-card>
    </v-dialog>

    <v-menu
      v-model="verMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
    >
      <v-list dense>
        <v-list-item
          v-for="(item, index) in opciones"
          :key="index"
          @click="abrirModalUsuarios( item )"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>


    <ModalContactos
    	v-if="dialogContactos.estatus"
    	:dialogContactos="dialogContactos"
    	:chats="chats"
    	:mensajeReenviar="mensajeReenviar"
    	@verChat="verChat"
    />

	  <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>
	</div>
</template>

<script>
	import axios from 'axios';

  import lamejs from 'lamejs';

  import RecordRTC from 'recordrtc';

	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';
  import AgregarArchivo        from '@/components/whatsapp/AgregarArchivo.vue'
  import ModalContactos        from '@/components/whatsapp/ModalContactos.vue'

  import validarErrores        from '@/mixins/validarErrores'
  import { Clipboard }         from "@capacitor/clipboard";

  import {mapGetters, mapActions } from 'vuex'
	import { Camera } from '@capacitor/camera';

	export default {

		components:{
      Alerta,
      carga,
      AgregarArchivo,
      ModalContactos
    },

    mixins: [ validarErrores ],

		props:[
			'mensajes',
			'alumno',
			'wha',
			'chat',
			'verTodosLosMensajes',
			'asignacion',
			'numeroTwilio',
			'chats'
		],

		data: () => ({
      message: '',
      dialogArchivos: false,
      archivos:null,
      vistaPrevia: null,
      file: '',
      dragging: false,
      dialgImagen:false,
			url :'https://testfast.club/imagenes-whatsapp/',
			url2: 'https://escuelakpi.club/imagenes-whatsapp/',

			fullscreen: false,
			pdf:null,
			dialogPDF: false,
			
			imagen: null,
			grabando: false,
			mediaRecorder: null,
			chunks: [],
			audio: false,
			recording: false,
      blob: null,
      mp3Data: [],
      audioContext: null,
      audioInput: null,
      scriptProcessor: null,
      mp3Encoder: null,
      recorder: null,
      buffer: null,
      audioPath:null,

      longitudMensajes: 0,

      tipoArchivo: 0,
      clipboardContent: '',

      dialogMicrofono: false,

      dialogGrabacion: false,


      // Diseño para el audio
      mediaRecorder: null,
      audioStream: null,
      analyser: null,
      canvasCtx: null,

      audioURL: null,

      blobs: [], // Aquí se guardarán los segmentos de audio
    	combinedBlob: null, // Aquí se almacenará el audio combinado
    	audioVisible: false,
    	chunks: [], // Usaremos chunks en lugar de blobs

    	verMenu: false,
      x: 0,
      y: 0,
      opciones: [
        { title: 'Reenviar' },
        { title: 'Descargar' },
      ],

      dialogAudio: false,

      documentacion: [
      	'PDF FAST-INTENSIVO.pdf',
		    'PDF FAST-ONLINE SABATINO.pdf',
		    'PDF FAST-ONLINE.pdf',
		    'PDF FAST-SABATINO.pdf',
		    'PDF - INBI ADULTS ONLINE.pdf',
		    'PDF - INBI ADULTS ONLINE SABATINO.pdf',
		    'PDF - INBI ADULTS.pdf',
		    'PDF - INBI ADULTS SABATINO.pdf',
		    'PDF - INBI KIDS.pdf',
		    'PDF - INBI TEENS SABATINO.pdf',
		    'PDF - INBI TEENS.pdf',
		    'preventa editable.pdf'
		  ],

      mensajeReenviar: null,
      dialogContactos:{
      	estatus: false
      }
		}),

		computed:{

			...mapGetters('login', ['getdatosUsuario']),
			...mapGetters('twilio', ['getConversacionesM','getConversacionesC', 'getConversacionesG']),

			filterMensajes(){

        let mensajes = this.mensajes

				if( this.longitudMensajes != this.mensajes.length ){

					console.log('cargar')
					setTimeout(function(){document.getElementById('scroll-target').scrollTop = document.getElementById('scroll-target').scrollHeight},300);

					this.longitudMensajes = this.mensajes.length
					
				}

        return mensajes
      },

      bloqueo( ){
      	return this.chat.idetiquetas == 6 ? true : false
      }
		},

		created( ) {
			this.longitudMensajes = this.mensajes.length
		},

		mounted() {
	    // Agrega el listener solo cuando el componente está montado
	    window.addEventListener("keydown", this.handlePaste);
	  },

	  beforeDestroy() {
	    // Elimina el listener al destruir el componente
	    window.removeEventListener("keydown", this.handlePaste);
	  },


		whatch: {

			mensajes( ){

				console.log('Hubo un cambio')
			},


			dialogArchivos( val ){

				console.log( val )

			}
		},

		mounted( ){
  		this.canvas = this.$refs.audioCanvas;
  		console.log( this.canvas )
		},

		methods: {

			...mapActions('twilio', ['guardarConversacionesM','guardarConversacionesC', 'guardarConversacionesG']),

			initialize () {
				this.cargar = true
				this.usuarios = []
				return this.$http.get('whatsapp.usuarios').then(response=>{
					this.usuarios = response.data
					this.cargar      = false
				}).catch( error =>{
					this.validarError( error.response.data.message )
				}).finally( () => { this.cargar = false })
			},

			show (mensaje,e) {
				this.mensajeReenviar = mensaje
				console.log( this.mensajeReenviar )
        e.preventDefault()
        this.verMenu = false
        this.x = e.clientX
        this.y = e.clientY
        this.$nextTick(() => {
          this.verMenu = true
        })
      },

			async handlePaste(event) {
	      // Verifica si el usuario presionó Ctrl + V
	      if (event.ctrlKey && event.key === "v") {
	        try {
	          // Obtiene el contenido del portapapeles
	          const { type, value } = await Clipboard.read();

	          // Validamos que sea de tipo imagen
	          if (type == "image/png") {
	            // Convertimos el base64 en una imagen
	            this.vistaPrevia    = null
	            this.file           = null
	            this.dialogArchivos = true
	            const file          = this.convertFile(value, "imagen.png");
	            this.createFile(file);
	          }

	          if( type == 'text/plain' ){

	          	this.message = value

	          }

	        } catch (error) {
	          console.error("Error al leer el portapapeles:", error);
	        }
	      }
	    },

			sendMessage( value ){

				let etapa = this.chat.idetapa

				// validar la etapa
				if( this.file ){

					// Existe un archivos, hay que validar el nombre del archivo, para poder saber siiiiiii el nombre es uno de los validos
					if( this.documentacion.includes( this.file.name ) ){

						etapa = 4

					}
				}


        // Preparamos el mensaje a enviar
      	var formData = new FormData();
	      formData.append("from"           , this.numeroTwilio );
	      formData.append("body"           , this.message );
	      formData.append("to"             , this.chat.usuario );
	      formData.append("audio"          , this.audio ? '1' : '0' );
	      formData.append("rutaAudio"      , this.audioPath );

	      // Prospectos
	      formData.append("idprospectos"   , this.chat.idprospectos );
	      formData.append("idetapa"        , etapa );
	      formData.append("iderp"          , this.getdatosUsuario.iderp );

	      // Si hay un archivo, se agrega el archivo al formData que se enviara para que twilio lo procese
	      if( this.file ){ formData.append("file", this.file ) }
	      
	      let type = 1
	      // Sacar la validación del tipo de archivo que se está enviando 
	      	      
	      if( this.file && !this.audio){


	      	const nombreSplite = this.file.name.split('.')

	      	const exstension = nombreSplite[ nombreSplite.length -1 ]

	      	// Validamos si son imagenes 
	      	type = ['jpg','png','PNG','webp','svg'].includes( exstension ) ? 2 : type

	      	// Validamos si son imágenes
	      	type = ['PDF','pdf'].includes( exstension ) ? 4 : type

	      	// Validar si es un audio
	      	type = ['mp4','mp4'].includes( exstension ) ? 4 : type

	      }

	      // Validar si se envió un audio
	      type = this.audio ? 3 : type

	      // Hacemos la simulación de mensajes
	      this.mensajes.push({
					idmensajes: 0,
					idusuarios: 0,
					usuario: this.chat.usuario,
					mensaje: this.message,
					twilio: "whatsapp:+5218187047279",
					mio: 1,
					fecha_creacion: "2025-01-04 16:17:02",
					type,
					MediaUrl0: "",
					messageSid: "41135435165435135135315",
					imagenFake: this.vistaPrevia,
					audioFake : this.file,
					fake      : true
	      })

	    	// Enviamos el chat hasta abajo
	      this.scrolear( )

	      // Limpiamos todo
      	this.cancelar( )


	      // Se envía el mensaje, aquí deben pasar 2 cosas, la simulación del mensaje y que realmente se envíe el mensaje
        return this.$http.post('twilio.send.message', formData ).then( ( response )=>{

        	this.chat.idetapa = ['1',1,'2',2,'7',7].includes( this.chat.idetapa ) ? 5 : etapa
        	// Emitimos los mensajes a la pestaña inicial para validar la información
					this.$emit('verChat', this.chat )
					this.$emit('validayGuardaMensajes')

      		// Limpiar dialogo de archivos
          this.cargar  = false


        }).catch( error =>{
        	console.log( error )
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
			},

      cancelar( ){
      	this.chunks          = []
      	this.vistaPrevia     = null
	      this.archivos        = null
	      this.file            = '';
      	this.dialogArchivos  = false
				this.message         = ''
				this.audio           = false
				this.grabando        = false
				this.imagen          = null
				this.mediaRecorder   = null
				this.chunks          = []
				this.recording       = false
	      this.blob            = null
	      this.mp3Data         = []
	      this.audioContext    = null
	      this.audioInput      = null
	      this.scriptProcessor = null
	      this.mp3Encoder      = null
	      this.recorder        = undefined
	      this.buffer          = null
	      this.audioPath       = null
        this.dialogGrabacion = false
        this.dialogAudio     = false
      },

      getBase64(file) {
	      var me = this
	      var reader = new FileReader();
	      reader.readAsDataURL(file);
	      reader.onload = () => {
	        
		      if( file ){

		      	const nombreSplite = file.name.split('.')

		      	const exstension = nombreSplite[ nombreSplite.length -1 ]

		      	// Validamos si son imagenes 
		      	this.tipoArchivo = ['jpg','png','PNG','webp','svg'].includes( exstension ) ? 2 : this.tipoArchivo

		      	// Validamos si son imágenes
		      	this.tipoArchivo = ['PDF','pdf'].includes( exstension ) ? 4 : this.tipoArchivo

		      }

	        this.vistaPrevia = reader.result

	      };
	    },

			onChange(e) {
	      var files = e.target.files || e.dataTransfer.files;
	      if (!files.length) {
	        this.dragging = false;
	        return;
	      }
	      
	      this.createFile(files[0]);
	    },

	    createFile(file) {
	      if (file.size > 40000000) {
	        alert('please check file size no over 40 MB.')
	        this.dragging = false;
	        return;
	      }
	      
	      this.file = file;
	      this.getBase64(this.file)
	      this.dragging = false;
	    },

	    verImagen( value ){
        this.imagen      = value
        this.dialgImagen = true
      },

      verPDF( value ){
        this.pdf         = value
        this.dialogPDF   = true
      },

      grabarAudio( ){
        
        // Activamos el navegador para poder iniciar una grabación
        navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
	      	
	      	// Este cuadro de dialogo se abrirá solo pra mi
	      	if( [334,1].includes( this.getdatosUsuario.iderp )  ){
	      		
	      		this.dialogGrabacion = true

	      	}


	      	// Variables de grabación
	      	this.grabando = true
	      	this.audio    = true
          this.recording = true;

          // Crearmos el objeto de grabación
          this.recorder = RecordRTC(stream, {
            type: 'audio',
            mimeType: 'audio/mpeg',
            recorderType: RecordRTC.StereoAudioRecorder,
            desiredSampRate: 16000,
            numberOfAudioChannels: 1
          });

          // Iniciamos la grabación del audio
          this.recorder.startRecording();

        }).catch(error => {
        	
        	// No hay permisos, mostramos la alerta
          this.dialogMicrofono = true
        
        });
      },

      async sendAudio(){

      	/*
	      	this.recording = false;
		      this.recorder.stopRecording( async () => {
		        this.blob = this.recorder.getBlob();

		        // Una vez grabado el blob, es necesario enviarlo al servidor para que lo convierta a MP3
		        this.audioPath = await this.saveAudioConverter( this.blob ).then( response => response )

		        console.log( this.audioPath )

		        this.audio = true
		        this.file  = true

		        this.sendMessage()
		        
		      });
		    */

		    if( this.blob ){

		    	this.sendMessage()
		    
		    }else{

		    	this.recording = false;
		      this.recorder.stopRecording( async () => {
		        this.blob = this.recorder.getBlob();

		        // Una vez grabado el blob, es necesario enviarlo al servidor para que lo convierta a MP3
		        this.audioPath = await this.saveAudioConverter( this.blob ).then( response => response )

		        console.log( this.audioPath )

		        this.audio = true
		        this.file  = true

		        this.sendMessage()
		        
		      });

		    }
      },

      saveAudioConverter( ) {
      	return new Promise((resolve, reject ) => {

      		// Crear la ruta de axios
	      	const url = axios.defaults.baseURL + 'audio.parse'

	      	// Indicarle que es un dato blob y que enviara el archivo y lo convierta
		      axios.post(url, this.blob, { headers: { 'Content-Type': 'audio/mpeg' } } ).then(response => {
		      	
		      	// Si todo bien, retornamos la información
	          resolve( response.data )
	        }).catch(error => {

	        	// Si no, regresamos el error
	          reject( error )
	        });
      	})
	    },

	    deleteAudio(){
	    	this.recorder.reset()
			  this.recorder.clearRecordedData()
	      this.recorder.destroy()
	      this.cancelar()
	    },

	    pauseAudio( ){

	    	if( [334,1].includes( this.getdatosUsuario.iderp )  ){
	      		
		    	// Detener la grabación 
			    this.recording = false;
		      this.recorder.stopRecording( async () => {
			    	this.grabando = false
		        this.blob = this.recorder.getBlob();

		        // Una vez grabado el blob, es necesario enviarlo al servidor para que lo convierta a MP3
		        this.audioPath = await this.saveAudioConverter( this.blob ).then( response => response )

		        console.log( this.audioPath )

		        this.audio = true
		        this.file  = true

		      });

      	}else{
      		this.grabando = false
		    	this.recorder.pauseRecording()
      	}


	    },

	    playAudio(){


		    /*if( this.mediaRecorder ) {

		      this.mediaRecorder.resume();
		      this.grabando = true;
	        this.audioVisible = false;
		   
		    }*/

	    	if( [334,1].includes( this.getdatosUsuario.iderp )  ){
		    
				  const tempAudioURL = URL.createObjectURL(this.blob);

				  // Crear un nuevo audio y reproducirlo
				  const audio = new Audio(tempAudioURL);

				  audio.play();
				  
		    }else{
		    	this.mediaRecorder.resume();
		      this.grabando = true;
	        this.audioVisible = false;
		    }

	    },

	    scrolear( ){
				setTimeout(function(){document.getElementById('scroll-target').scrollTop = document.getElementById('scroll-target').scrollHeight},300);
	    },

	    reproducirDuranteGrabacion() {
			  this.recorder.getInternalRecorder().requestData();
			  const audioBlob = this.recorder.getBlob();
			  const tempAudioURL = URL.createObjectURL(audioBlob);

			  // Crear un nuevo audio y reproducirlo
			  const audio = new Audio(tempAudioURL);
			  audio.play();
			},

	    async pegar(event) {
        if (event.code == "KeyV") {
          const { type, value } = await Clipboard.read();

          // Validamos que sea de tipo imagen
          if (type == "image/png") {
            // Convertimos el base64 en una imagen
            const file = this.convertFile(value, "imagen.png");
            this.createFile(file);
          }
        }
      },

      async abrirPegar(event) {
        if (event.code == "KeyV") {
          const { type, value } = await Clipboard.read();

          // Validamos que sea de tipo imagen
          if (type == "image/png") {
            // Convertimos el base64 en una imagen
            this.vistaPrevia    = null
            this.file           = null
            this.dialogArchivos = true
            const file          = this.convertFile(value, "imagen.png");
            this.createFile(file);
          }
        }
      },

      abrirModalUsuarios( item ){
      	console.log( item )
      	const { title } = item 

      	if( title == "Descargar" ){
      		console.log( this.mensajeReenviar )

      		if( this.mensajeReenviar.mio == 1 ){
      			window.open('https://escuelakpi.club/imagenes-whatsapp/' + this.mensajeReenviar.MediaUrl0 , '_blank');
      		}else{
      			this.mensajeReenviar.MediaUrl0
      			window.open('https://testfast.club/imagenes-whatsapp/' + this.mensajeReenviar.MediaUrl0 , '_blank');
      		}

      	}else{
      		this.dialogContactos.estatus = true
      	}
      },

      convertFile(dataurl, filename) {
        var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
      },

      verChat( value ){
      	console.log( value )
      	this.$emit('verChat', value )
      },


      async startRecording() {
	      try {
	        const audio = await Camera.getPhoto({
	          resultType: CameraResultType.Uri,
	          source: CameraSource.Microphone,
	        });
	        this.audioFile = audio.webPath; // Ruta del archivo de audio
	        console.log('Audio grabado:', this.audioFile);
	      } catch (error) {
	        console.error('Error al grabar audio:', error);
	      }
	    },
	    
	    playRecording() {
	      if (this.audioFile) {
	        const audio = new Audio(this.audioFile);
	        audio.play();
	      } else {
	        console.warn('No hay archivo de audio para reproducir.');
	      }
	    },

	    async stopRecording() {
	      try {
	        const result = await Media.stopRecording();
	        this.audioPath = result.path; // Ruta del archivo grabado
	        console.log('Grabación detenida:', result.path);
	      } catch (err) {
	        console.error('Error al detener la grabación:', err);
	      }
	    },

		},
	}
</script>
<style  scoped>

	#sectionMessage{
		position: absolute;
	  top: 100%;
  	left: 0px;
  	width: 100%;
  	background-color: #e9edef;
  	padding: 10px;
	}

	#sectionAsignacion{
		position: absolute;
	  top: 40px;
  	left: 80%;
  	width: 100%;
  	padding: 10px;
	}

	#preguntas::-webkit-scrollbar {
		-webkit-appearance: none !important;
	}

	.v-data-table--fixed-header > #preguntas::-webkit-scrollbar:vertical {
		width: 4px !important;
	}

	#preguntas::-webkit-scrollbar:vertical{
		width: 4px !important;
	}

	#preguntas::-webkit-scrollbar-button:increment,#preguntas::-webkit-scrollbar-button{
		display: none;
	}

	#preguntas::-webkit-scrollbar:horizontal{
		display: none;
	}

	#preguntas::-webkit-scrollbar-thumb{
		background-color: #BABCBF;
		border-radius: 20px;
		border: 1px solid #ededed;
	}

	/**********************************/

	#scroll-target::-webkit-scrollbar {
		-webkit-appearance: none !important;
	}

	#scroll-target::-webkit-scrollbar:vertical{
		width: 4px !important;
	}

	#scroll-target::-webkit-scrollbar-button:increment,#scroll-target::-webkit-scrollbar-button{
		display: none;
	}

	#scroll-target::-webkit-scrollbar:horizontal{
		display: none;
	}

	#scroll-target::-webkit-scrollbar-thumb{
		background-color: #BABCBF;
		border-radius: 20px;
		border: 1px solid #ededed;
	}




	#mensajesChat::-webkit-scrollbar {
		-webkit-appearance: none !important;
	}

	.v-data-table--fixed-header > #mensajesChat::-webkit-scrollbar:vertical {
		width: 4px !important;
	}

	#mensajesChat::-webkit-scrollbar:vertical{
		width: 4px !important;
	}

	#mensajesChat::-webkit-scrollbar-button:increment,#mensajesChat::-webkit-scrollbar-button{
		display: none;
	}

	#mensajesChat::-webkit-scrollbar:horizontal{
		display: none;
	}

	#mensajesChat::-webkit-scrollbar-thumb{
		background-color: #BABCBF;
		border-radius: 20px;
		border: 1px solid #ededed;
	}

	.container {
		padding:0;
		background-color: #FFF; 
		box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
		height: 750px;
		margin-top: 25px;
	}

	/* ===== MENU ===== */
	.menu {
		float: left;
		height: 700px;;
		width: 70px;
		background: #4768b5;
		background: -webkit-linear-gradient(#4768b5, #35488e);
		background: -o-linear-gradient(#4768b5, #35488e);
		background: -moz-linear-gradient(#4768b5, #35488e);
		background: linear-gradient(#4768b5, #35488e);
		box-shadow: 0 10px 20px rgba(0,0,0,0.19);
	}

	.menu .items {
		list-style:none;
		margin: auto;
		padding: 0;
	}

	.menu .items .item {
		height: 70px;
		border-bottom: 1px solid #6780cc;
		display:flex;
		justify-content: center;
		align-items: center;
		color: #9fb5ef;
		font-size: 17pt;
	}

	.menu .items .item-active {
		background-color:#5172c3;
		color: #FFF;
	}

	.menu .items .item:hover {
		cursor: pointer;
		background-color: #4f6ebd;
		color: #cfe5ff;
	}

	/* === CONVERSATIONS === */

	.discussions {
		width: 100%;
		height: 702px;
		box-shadow: 0px 8px 10px rgba(0,0,0,0.20);
		overflow: hidden;
		display: inline-block;
	}

	.discussions .discussion {
		width: 100%;
		height: 90px;
		background-color: #FAFAFA;
		display:flex;
		align-items: center;
		cursor: pointer;
	}

	.discussions .search {
		display:flex;
		align-items: center;
		justify-content: center;
		color: #E0E0E0;
	}

	.discussions .search .searchbar {
		height: 40px;
		background-color: #FFF;
		width: 70%;
		padding: 0 20px;
		border-radius: 50px;
		border: 1px solid #EEEEEE;
		display:flex;
		align-items: center;
		cursor: pointer;
	}

	.discussions .search .searchbar input {
		margin-left: 15px;
		height:38px;
		width:100%;
		border:none;
		font-family: 'Montserrat', sans-serif;;
	}

	.discussions .search .searchbar *::-webkit-input-placeholder {
		color: #E0E0E0;
	}
	.discussions .search .searchbar input *:-moz-placeholder {
		color: #E0E0E0;
	}
	.discussions .search .searchbar input *::-moz-placeholder {
		color: #E0E0E0;
	}
	.discussions .search .searchbar input *:-ms-input-placeholder {
		color: #E0E0E0;
	}

	.discussions .message-active {
		height: 90px;
		background-color: #FFF;
		border-right: solid 5px #E91E63;
	}

	.discussions .discussion .photo {
		margin-left:20px;
		display: block;
		width: 45px;
		height: 45px;
		background: #E6E7ED;
		-moz-border-radius: 50px;
		-webkit-border-radius: 50px;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
	}

	.online {
		position: relative;
		top: 30px;
		left: 35px;
		width: 13px;
		height: 13px;
		background-color: #8BC34A;
		border-radius: 13px;
		border: 3px solid #FAFAFA;
	}

	.desc-contact {
		height: 43px;
		width:50%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.discussions .discussion .name {
		margin: 0 0 0 20px;
		font-family:'Montserrat', sans-serif;
		font-size: 11pt;
		color:#515151;
	}

	.discussions .discussion .message {
		margin: 6px 0 0 20px;
		font-family:'Montserrat', sans-serif;
		font-size: 9pt;
		color:#515151;
	}

	.timer {
		margin-left: 15%;
		font-family:'Open Sans', sans-serif;
		font-size: 11px;
		padding: 3px 8px;
		color: #BBB;
		background-color: #FFF;
		border: 1px solid #E5E5E5;
		border-radius: 15px;
	}

	.chat {
		width: calc(100%);
	}

	.chat .messages-chat {
		padding: 25px 10px;
	}

	.chat .messages-chat .message {
		display:flex;
		align-items: center;
		margin-bottom: 2px;
	}

	.chat .messages-chat .message .photo {
		display: block;
		width: 45px;
		height: 45px;
		background: #E6E7ED;
		-moz-border-radius: 50px;
		-webkit-border-radius: 50px;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
	}

	.chat .messages-chat .text {
		margin: 0 35px;
		background-color: #fff;
		padding: 15px;
		border-radius: 12px;
	}

	.text-only {
		white-space: pre-line;

	}

	.time {
		font-size: 12px;
		color:lightgrey;
		margin-bottom:10px;
		margin-left: 38px;
	}

	.response-time {
		float: right;
		margin-right: 40px !important;
	}

	.response {
		float: right;
		margin-right: 0px !important;
		margin-left: auto; /* flexbox alignment rule */
		max-width: 90%;
	}

	.noresponse {
		max-width: 90%;
	}

	.noresponse .text{
    box-shadow: 0 20px 27px 0 rgba(0,0,0,0.05)  !important;
	}

	.response .text {
		background-color: #c5e6c1 !important;
		color: black;
    box-shadow: 0 20px 27px 0 rgba(0,0,0,0.05)  !important;

	}

	.audio {
		background-color: #f1f3f4 !important;
	}

	.response .audio {
		background-color: #f1f3f4 !important;
	}

	.footer-chat {
		width: calc(65% - 66px);
		height: 80px;
		display:flex;
		align-items: center;
		position:absolute;
		bottom: 0;
		background-color: transparent;
		border-top: 2px solid #EEE;

	}

	.chat .footer-chat .icon {
		margin-left: 30px;
		color:#C0C0C0;
		font-size: 14pt;
	}

	.chat .footer-chat .send {
		color:#fff;
		background-color: #4f6ebd;
		position: absolute;
		right: 50px;
		padding: 12px 12px 12px 12px;
		border-radius: 50px;
		font-size: 14pt;
	}

	.chat .footer-chat .name {
		margin: 0 0 0 20px;
		text-transform: uppercase;
		font-family:'Montserrat', sans-serif;
		font-size: 13pt;
		color:#515151;
	}

	.chat .footer-chat .right {
		position: absolute;
		right: 40px;
	}

	.dropZone {
    width: 80%;
    height: 150px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone:hover {
    border: 2px solid #D8D8D8;
  }

  .dropZone:hover .dropZone-title {
    color: #1975A0;
  }

  .dropZone-info {
    color: #A8A8A8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  ..dropZone-title {
    color: #787878;
  }

  .dropZone input {
    position: absolute;
    cursor: pointer;
    top: 0px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .dropZone-upload-limit-info {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  .dropZone-over {
    background: #D8D8D8;
    opacity: 0.8;
  }

  .dropZone-uploaded {
    width: 80%;
    height: 200px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone-uploaded-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #D8D8D8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  .removeFile {
    width: 200px;
  }


	.audio-recorder {
	  text-align: center;
	  position: relative;
	}

	canvas {
	  width: 80%;
	  height: 30px;
	  border-radius: 50px;
	  background: #f9f9f9;
	  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
	}


.audio-recorder {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 150px;
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
}

.audio-canvas {
  width: 80%;
	height: 30px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.recording-indicator {
  color: #ff4b5c;
  font-weight: bold;
  margin-top: 10px;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.message-container {
  position: relative;
  display: inline-block;
  max-width: 100%;
}

.text {
  position: relative;
  margin-right: 30px; /* Espacio para el botón */
}

.forward-btn {
  position: absolute;
  right: -30px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.2s ease;
}

.message-container:hover .forward-btn {
  opacity: 1;
}

</style>

