<template>
	<v-dialog
    v-model="dialogContactos.estatus"
    :max-width="500"
  >
		<v-card >
		  <v-card-title class="text-subtitle-1">
		    Reenviar mensajes
		  </v-card-title>
		  <v-card-text >
		  	<v-text-field
		  	  label="Buscar"
		  	  filled
		  	  dense
		  	  clearable
		  	  v-model="buscar"
		  	  append-icon="mdi-magnify"
		  	  hide-details
		  	></v-text-field>
		  	<v-container
          :height="tamanioPantalla"
          id="scroll-target"
          :style="`max-height: ${tamanioPantalla}px`"
          class="overflow-y-auto elevation-0"
        >
			    <v-list dense class="elevation-0">
			      <template v-for="(chatW, i) in filterChats" :kei="i" v-if="i <= limite">
			        <v-divider inset v-if="i > 0"></v-divider>
			        <v-list-item
			          @click="chatSeleccionado( chatW )"
			          :class="chatW == chat ? 'green lighten-4' : '' "
			        >
			          <!-- AVATAR -->
			          <v-list-item-avatar :color="!chatW.nombre ? '#00A548' : 'grey lighten-3'"  v-if="chatW != chat">
			            <v-icon color="white" v-if="!chatW.nombre">mdi-account</v-icon>
			            <span v-if="chatW.nombre">{{ chatW.nombre }}</span>
			          </v-list-item-avatar>

			          <v-list-item-avatar color="green"  v-else>
			            <v-icon color="white">mdi-check</v-icon>
			          </v-list-item-avatar>

			          <!-- contenido del contacto -->
			          <v-list-item-content>

			            <!-- Nombre del contacto -->
			            <v-list-item-title>
			              <v-icon v-if="chatW.idetiquetas" :color="chatW.color">mdi-label</v-icon>
			              {{ getNombre(chatW) }}
			              <v-chip x-small v-if="chatW.idetapa == 1" dark color="red">Atrasado</v-chip>
			              <v-chip x-small v-if="chatW.idetapa == 2" dark color="green">Nuevo</v-chip>
			              <v-chip x-small v-if="chatW.idetapa == 3" dark color="pink">Inducción</v-chip>
			              <v-chip x-small v-if="chatW.idetapa == 4" dark color="orange">Con info</v-chip>
			              <v-chip x-small v-if="chatW.idetapa == 5" dark color="brown">Sin info</v-chip>
			              <v-chip x-small v-if="chatW.idetapa == 7" dark color="purple darken-4">2do Contacto</v-chip>
			            </v-list-item-title>

			            <!-- Cuerpo del contacto -->
			            <v-list-item-subtitle >
			              <!-- VISTO -->
			              <v-icon 
			                small 
			                class="mr-1" 
			                v-if="chatW.mio" 
			                color="grey"
			              >
			                mdi-check-all
			              </v-icon>


			              <!-- MOSTRAR ULTIMO MENSAJE -->
			              <span>
			                <v-tooltip bottom>
			                  <template v-slot:activator="{ on, attrs }">
			                    <span
			                      v-bind="attrs"
			                      v-on="on"
			                    > 
			                      <span v-if="chatW.type == 1">{{ chatW.mensaje }}</span>
			                      <span v-if="chatW.type == 2">Imagen</span>
			                      <span v-if="chatW.type == 3">Audio</span>
			                      <span v-if="chatW.type == 4">PDF</span>
			                    </span>
			                  </template>
			                  <!-- mostrar texto -->
			                  <span v-if="chatW.type == 1">{{ chatW.mensaje }}</span>

			                  <!-- Mostrar miniatura de la imagen -->
			                  <span v-if="chatW.type == 2">
			                    <img v-if="chatW.mio == 0" :src="url2 + chatW.MediaUrl0" alt="Red dot" width="100"/>
			                    <img v-if="chatW.mio == 1" :src="url2 + chatW.MediaUrl0" alt="Red dot" width="100"/>
			                  </span>

			                  <!-- Tipo de mensaje es un audio -->
			                  <span v-if="chatW.type == 3">Audio, abrir para escuchar</span>

			                  <!-- Tipo PDF -->
			                  <span v-if="chatW.type == 4">PDF, abrir para verlo</span>
			                </v-tooltip>
			              </span>   

			            </v-list-item-subtitle>

			          </v-list-item-content>


			          <v-list-item-icon>
			            <!-- HORA DEL CHAT -->
			            <v-list-item-action-text >{{  chatW.ultimmensaje }}</v-list-item-action-text>

			            <!-- Marcador para saber cuando mensaje no leídos son -->
			            <v-chip v-if="chatW.sinleer > 0 || chatW.mio == 0" :color="chatW.mio == 0 ? 'green' : ''" small dark class="pa-2">
			              {{ 1 }}
			            </v-chip>
			          </v-list-item-icon>

			        </v-list-item>

			        <div v-if="limite == i" >
			          <v-btn color="success" @click="limite += 50" block tile>Mostrar más mensajes +50</v-btn>
			        </div>
			      </template>
			    </v-list>
				</v-container>
		  </v-card-text>
		  <v-card-actions class="mb-6">
		  	<v-btn 
		  		color="green"
		  		fab
		  		dark
		  		absolute
		  		right
		  		button
		  		v-if="chat"
		  		@click="enviarMensaje( )"
		  	>
		    	<v-icon >mdi-send</v-icon>
		  	</v-btn>
		  </v-card-actions>
		</v-card>
	</v-dialog>
</template>
<script>
  import axios          from 'axios';
  import { mapGetters, mapActions } from 'vuex'

  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import MensajesWppTwilio     from '@/components/whatsapp/MensajesTwilioWpp.vue';
  import validarErrores        from '@/mixins/validarErrores'

  export default {

  	props:[
  		'chats',
  		'dialogContactos',
  		'mensajeReenviar'
  	],

    components:{
      Alerta,
      carga,
      MensajesWppTwilio,
    },

    mixins: [ validarErrores ],


    data: () => ({
      chat:null,
      limite: 25,
      url :'https://academicokpi.club/imagenes-whatsapp/',
      url2: 'https://escuelakpi.club/imagenes-whatsapp/',
      offsetTop: 0,
      buscar:'',

      misChats: []
    }),

    computed: {
      ...mapGetters('login', ['getdatosUsuario']),

      
      tamanioPantalla () {
        return this.$vuetify.breakpoint.height - 330
      },

      filterChats(){

        let chats = this.misChats

        if( this.buscar ){
          
          chats = chats.filter( el => el.usuario.match( this.buscar ))

        }

        return chats
      },
    },

    watch:{
      
    },

    async created (){
    	this.misChats = JSON.parse(JSON.stringify(this.chats));
    },

    methods: {
    	getNombre( texto ){

        let nombre = texto.usuario.replace('whatsapp:+52','')

        return nombre
      },

      onScroll (e) {
        this.offsetTop = e.target.scrollTop
      },

      chatSeleccionado( chat ){
      	this.chat = chat

      },

      enviarMensaje( ){

        this.mensajeReenviar.usuario = this.chat.usuario
      	this.mensajeReenviar.iderp   = this.getdatosUsuario.iderp

      	return this.$http.post('twilio.reenviar.message', this.mensajeReenviar ).then( ( response )=>{

      		this.$emit('verChat', this.chat )
      		this.chat = null
      		this.dialogContactos.estatus = false
      		// Limpiar dialogo de archivos
          this.cargar  = false

        }).catch( error =>{
        	console.log( error )
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

      },



    },
  }
</script>

<style  scoped>

  #mensajesChat{
    background-image: url("/fondo_whatsapp.jpg");
    background-repeat: repeat-y;
    background-repeat: repeat-x;
    background-size:contain;
  }

  /**********************************/

  #scroll-target::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  #scroll-target::-webkit-scrollbar:vertical{
    width: 4px !important;
  }

  #scroll-target::-webkit-scrollbar-button:increment,#scroll-target::-webkit-scrollbar-button{
    display: none;
  }

  #scroll-target::-webkit-scrollbar:horizontal{
    display: none;
  }

  #scroll-target::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }

  .discussions .message-active {
    height: 90px;
    background-color: #FFF;
    border-right: solid 5px #E91E63;
  }

  .discussions .discussion .photo {
    margin-left:20px;
    display: block;
    width: 45px;
    height: 45px;
    background: #E6E7ED;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .online {
    position: relative;
    top: 30px;
    left: 35px;
    width: 13px;
    height: 13px;
    background-color: #8BC34A;
    border-radius: 13px;
    border: 3px solid #FAFAFA;
  }

  .desc-contact {
    height: 43px;
    width:50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .discussions .discussion .name {
    margin: 0 0 0 20px;
    font-family:'Montserrat', sans-serif;
    font-size: 11pt;
    color:#515151;
  }

  .discussions .discussion .message {
    margin: 6px 0 0 20px;
    font-family:'Montserrat', sans-serif;
    font-size: 9pt;
    color:#515151;
  }

  .timer {
    margin-left: 15%;
    font-family:'Open Sans', sans-serif;
    font-size: 11px;
    padding: 3px 8px;
    color: #BBB;
    background-color: #FFF;
    border: 1px solid #E5E5E5;
    border-radius: 15px;
  }

  .chat {
    width: calc(100%);
  }

  .chat .messages-chat {
    padding: 25px 10px;
  }

  .chat .messages-chat .message {
    display:flex;
    align-items: center;
    margin-bottom: 2px;
  }

  .chat .messages-chat .message .photo {
    display: block;
    width: 45px;
    height: 45px;
    background: #E6E7ED;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .chat .messages-chat .text {
    margin: 0 35px;
    background-color: #f6f6f6;
    padding: 15px;
    border-radius: 12px;
  }

  .text-only {
    white-space: pre-line;
  }

  .time {
    font-size: 12px;
    color:lightgrey;
    margin-bottom:10px;
    margin-left: 38px;
  }

  .response-time {
    float: right;
    margin-right: 40px !important;
  }

  .response {
    float: right;
    margin-right: 0px !important;
    margin-left: auto; /* flexbox alignment rule */
    max-width: 90%;
  }

  .noresponse {
    max-width: 90%;
  }

  .response .text {
    background-color: #E91E63 !important;
    color: white;
  }

  .footer-chat {
    width: calc(65% - 66px);
    height: 80px;
    display:flex;
    align-items: center;
    position:absolute;
    bottom: 0;
    background-color: transparent;
    border-top: 2px solid #EEE;
    
  }

  .chat .footer-chat .icon {
    margin-left: 30px;
    color:#C0C0C0;
    font-size: 14pt;
  }

  .chat .footer-chat .send {
    color:#fff;
    background-color: #4f6ebd;
    position: absolute;
    right: 50px;
    padding: 12px 12px 12px 12px;
    border-radius: 50px;
    font-size: 14pt;
  }

  .chat .footer-chat .name {
    margin: 0 0 0 20px;
    text-transform: uppercase;
    font-family:'Montserrat', sans-serif;
    font-size: 13pt;
    color:#515151;
  }

  .chat .footer-chat .right {
    position: absolute;
    right: 40px;
  }

  #idScrollApp::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  .v-data-table--fixed-header > #idScrollApp::-webkit-scrollbar:vertical {
    width: 8px !important;
  }

  #idScrollApp::-webkit-scrollbar:vertical{
    width: 8px !important;
  }

  #idScrollApp::-webkit-scrollbar-button:increment,#idScrollApp::-webkit-scrollbar-button{
    display: none;
  }

  #idScrollApp::-webkit-scrollbar:horizontal{
    height: 8px !important;
  }

  #idScrollApp::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }

</style>
